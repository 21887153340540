import React, { useState, useEffect, useContext  } from "react"
import Axios from 'axios';
import { UserContext } from '../App';
import { useNavigate } from "react-router-dom";
// import {logo} from '../assets/img/Rodof.png';

export const getStorageUser = () => {
    const userStorage = sessionStorage.getItem("user");
    if (userStorage) {
        const user = JSON.parse(userStorage);
        return user;
    }
    return null;
};

export const checkIsLoggedIn = () => {
    const userStorage = sessionStorage.getItem("user");
    if (userStorage) {
        const user = JSON.parse(userStorage);
        if (user.id) {
            return true;
        }
    }
    return false;
};


const Login = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    // console.log('BASE_URL2', BASE_URL);
   
    let [authMode, setAuthMode] = useState("signin");
    const { user:userContext, setUser } = useContext(UserContext);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isLoggedIn, setLoginStatus] = useState(false)
    const [requestStatus, setRequestStatus] = useState('idle')
    const navigate = useNavigate(); 
    const [errors, setErrors] = useState({});

    const changeAuthMode = () => {
        setAuthMode(authMode === "signin" ? "signup" : "signin")
    }

    const loginButton = async() => {
        setRequestStatus('pending')
        const response = await Axios.post(`${BASE_URL}/login`, {
            username: username,
            password: password,
        })
        // console.log('loginButton', response.data);
        if (response.data.id) {
            // setErrors({eroarePlata: "Aplicatia nu a fost platita in intregime pana la termenul stabilit! Efectueaza plata pentru a putea folosi aplicatia!"});
            

            sessionStorage.setItem("user", JSON.stringify(response.data));
            setUser(response.data);
            navigate("/members/dashboard", { replace: true });
        }else
        {
            setErrors({newPassword1: "Utilizator sau parola incorecta!"});
        }
    };
    useEffect(() => {
        const islogg = checkIsLoggedIn();
        if (islogg) {
            setLoginStatus(true);
        }
    }, [requestStatus, errors]);
    // const logo = require('../assets/img/logo.jpg');
    const logo = require('../assets/img/Rodof.png');

    if (authMode === "signin") {
        return (
            <div className="Auth-form-container">
                <form className="Auth-form">
                    <div className="Auth-form-content">
                        <div className="form-group mt-3">
                            <img src={logo} alt="" width="250px" height="250px" style={{paddingBottom:30}} />
                        </div>
                        <h3 className="Auth-form-title">Autentificare</h3>
                        {/* <div className="text-center">
                            Not registered yet?{" "}
                            <span className="link-primary" onClick={changeAuthMode}>
                                Sign Up
                            </span>
                        </div> */}
                        <div className="form-group mt-3">
                            <label>Utilizator</label>
                            <input
                                type="text"
                                className="form-control mt-1"
                                id="floatingInput"
                                placeholder="Enter email"
                                onChange={(event)=> {
                                    setUsername(event.target.value);
                                }}
                            />
                        </div>
                        <div className="form-group mt-3">
                            <label>Parola</label>
                            <input
                                type="password"
                                className="form-control mt-1"
                                id="floatingInput1"
                                placeholder="Enter password"
                                onChange={(event)=> {
                                    setPassword(event.target.value);
                                }}
                            />
                        </div>
                        <div className="d-grid gap-2 mt-3">
                            <button type="button" className="btn btn-primary" onClick={ loginButton } >
                                Trimite
                            </button>
                        </div>
                        {/* <p className="text-center mt-2">
                            Ai uitat <a href="#">parola?</a>
                        </p> */}
                        {errors.newPassword1 ? (
                            <p className="error">{errors.newPassword1}</p>
                        ) : null}
                        {errors.eroarePlata ? (
                            <p className="error"><b>{errors.eroarePlata}</b></p>
                        ) : null}
                    </div>
                </form>
            </div>
        )
    }

    return (
        <div className="Auth-form-container">
            <form className="Auth-form">
                <div className="Auth-form-content">
                    <h3 className="Auth-form-title">Sign In</h3>
                    <div className="text-center">
                        Already registered?{" "}
                        <span className="link-primary" onClick={changeAuthMode}>
                            Sign In
                        </span>
                    </div>
                    <div className="form-group mt-3">
                        <label>Full Name</label>
                        <input
                            type="email"
                            className="form-control mt-1"
                            placeholder="e.g Jane Doe"
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label>Email address</label>
                        <input
                            type="email"
                            className="form-control mt-1"
                            placeholder="Email Address"
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label>Password</label>
                        <input
                            type="password"
                            className="form-control mt-1"
                            placeholder="Password"
                        />
                    </div>
                    <div className="d-grid gap-2 mt-3">
                        <button type="button" className="btn btn-primary">
                            Submit
                        </button>
                    </div>
                    <p className="text-center mt-2">
                        Forgot <a href="#">password?</a>
                    </p>
                </div>
            </form>
        </div>
    )
}

export default Login;
import React, { useState, useEffect } from "react";
import Axios from "axios";
import moment from "moment";
import '../../../App.css';

const CalendarBar = (props) => {
    const [dailyBokings, setDailyBokings] = useState([]);
    const carId = props.carId;
    const date = props.date;
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    // console.log({ carId: carId, date: date })

    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    
    function getFirstDayOfMonth(year, month) {
        return new Date(year, month, 1);
    }

    const firstDayOfMonth = getFirstDayOfMonth(
        date.getFullYear(),
        date.getMonth(),
    );

    function getLastDayOfMonth(year, month) {
        return new Date(year, month + 1, 0);
    }

    const lastDayOfMonth = getLastDayOfMonth(
        date.getFullYear(),
        date.getMonth(),
    );

    // console.log({ year: year, month: month, firstDayOfMonth: firstDayOfMonth, lastDayOfMonth: lastDayOfMonth })

    const firstDayOfMonth2 = moment(firstDayOfMonth).format('YYYY-MM-DD');
    const lastDayOfMonth2 = moment(lastDayOfMonth).format('YYYY-MM-DD');

    // console.log({ year2: year, month2: month, firstDayOfMonth2: firstDayOfMonth2, lastDayOfMonth2: lastDayOfMonth2 })

    const userData = {
        // carId: props.carId,
        month: month,
        year: year,
        firstDayOfMonth: firstDayOfMonth2,
        lastDayOfMonth: lastDayOfMonth2,
    };

    const getBookings = async (userData) => {
        const response = await Axios.post(`${BASE_URL}/rentedCarsForCalendarBar`, userData);
        // console.log("RespData",response.data)
        return response.data;
    }


    const toDayBokings = (date, bookings, carId) => {
        const filteredBookings = bookings.filter((booking) => booking.carId === carId);
        // console.log({ filteredBookings: filteredBookings });
        const dayBokings = [];
        const daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    
        // Funcție utilitară pentru a seta timpul la începutul zilei (ora 00:00:00)
        const normalizeDate = (date) => {
            const normalized = new Date(date);
            normalized.setHours(0, 0, 0, 0);
            return normalized;
        };
    
        for (let i = 1; i <= daysInMonth; i++) {
            const dayBoking = {
                day: i,
                booked: false, // Implicit nu este rezervat
            };
            const currentDay = normalizeDate(new Date(date.getFullYear(), date.getMonth(), i));
            // console.log("day", dayBoking);
    
            for (let j = 0; j < filteredBookings.length; j++) {
                const startDate = normalizeDate(new Date(filteredBookings[j].dataInceput));
                const endDate = normalizeDate(new Date(filteredBookings[j].dataSfarsit));
    
                // Verificare dacă ziua curentă este în intervalul de rezervare
                if (currentDay >= startDate && currentDay <= endDate) {
                    dayBoking.booked = true;
                    dayBoking.rentId = filteredBookings[j].rentId;
                    // console.log("dataEnd", endDate);
                }
            }
            dayBokings.push({ ...dayBoking }); // Copierea obiectului pentru a evita referințele
        }
    
        return dayBokings;
    };

    useEffect(() => {
        
        (async() =>  {const bookings = await getBookings(userData)
        const dayBokings = toDayBokings(date, bookings, carId);
        // console.log({ getBookingsPerDay: dayBokings })
        setDailyBokings(dayBokings);
        })();
    }, [date])

    // console.log("dailyBokings", dailyBokings);

    return (
        <div className="calendarBar">
            {dailyBokings.map((dayBoking, index) => {
                if (dayBoking.booked) {
                    return (
                        <div className="dateBusy" key={index}>
                            <div className="day">{dayBoking.day}</div>
                        </div>
                    )
                } else {
                    return (
                        <div className="dateFree" key={index}>
                            <div className="day">{dayBoking.day}</div>
                        </div>
                    )
                }
            })}
        </div>
    );
};

export default CalendarBar;